<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <b-card title="Questions">
          <b-row
            v-for="(section, index) in questions"
            :key="index"
          >
            <b-col v-if="index !== 'null'"><h4 class="p-1 mb-2 rounded bg-light">
              {{ index }}
            </h4></b-col>
            <b-col
              cols="12"
              class="px-2"
            >
              <b-row
                v-for="(question, index) in section"
                :key="index"
              >
                <b-col>
                  <b-form-group
                    :label="question.question"
                    :label-for="`question-${index}`"
                    :label-class="`h5`"
                  >
                    <b-form-input
                      v-if="question.data_type === 'Short text' || question.data_type === '' || question.data_type === 'Email' || question.data_type === 'Website URL'"
                      :id="`answer-${index}`"
                      v-model="question.duediligence_startupresponsetables[0].answer"
                      type="text"
                      placeholder="Your answer"
                      required
                    />
                    <b-form-input
                      v-if="question.data_type === 'Number'"
                      :id="`answer-${index}`"
                      v-model="question.duediligence_startupresponsetables[0].answer"
                      type="number"
                      placeholder="Your answer (in numbers)"
                      required
                    />
                    <b-form-textarea
                      v-if="question.data_type === 'Long text'"
                      :id="`answer-${index}`"
                      v-model="question.duediligence_startupresponsetables[0].answer"
                      placeholder="Your response"
                      required
                    />
                    <v-select
                      v-if="question.data_type === 'Dropdown'"
                      :id="`answer-${index}`"
                      v-model="question.duediligence_startupresponsetables[0].answer"
                      append-to-body
                      :options="['Option 1',
                                 'Option 2',
                                 'Option 3',
                                 'Option 4',]"
                      label="Choose from the following"
                      placeholder="Select from list"
                    />
                    <b-form-file
                      v-if="question.data_type === 'Document' || question.data_type === 'Upload File'"
                      :id="`answer-${index}`"
                      v-model="question.duediligence_startupresponsetables[0].answer"
                      accept="image/jpeg, image/png, image/gif"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="px-2 d-flex align-items-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 mt-1 py-1"
                @click="updateAnswer"
              >
                <span>Submit Answers</span>
              </b-button>
              <b-form-group
                v-if="$route.params.aid"
                label="Update Score"
                label-for="scoreLink"
                class="mb-0 flex-grow-1"
              >
                <b-form-input
                  id="scoreLink"
                  readonly
                  :value="scoreLink"
                />
              </b-form-group>
              <a
                v-if="$route.params.aid"
                :href="scoreLink"
                class="rounded btn"
              >
                <BIconBoxArrowUpRight />
                <a />

              </a></b-col>
          </b-row>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormFile, BRow, BCard, BFormTextarea, BIconBoxArrowUpRight, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BButton,
    BCard,
    BFormTextarea,
    BIconBoxArrowUpRight,
    BOverlay,
  },
  data() {
    return {
      applicationData: [],
      mutationLoading: false,
    }
  },
  computed: {
    questions() {
      this.applicationData.map(el => (el.duediligence_startupresponsetables.length === 0 ? el.duediligence_startupresponsetables.push({ answer: '' }) : el.duediligence_startupresponsetables))
      return this.groupByKey(this.applicationData, 'section')
    },
    answers() {
      const arr = []
      this.applicationData.map(r => arr.push(Object.assign(r.duediligence_startupresponsetables[0], {
        startup_id: this.$route.params.sid,
        startup_assessment_id: r.id,
      })))
      return arr
    },
  },
  methods: {
    groupByKey(array, key) {
      return Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
    },
    updateAnswer() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation ($answers: [duediligence_startupresponsetable_insert_input!]!) {
          insert_duediligence_startupresponsetable(objects: $answers, on_conflict: {constraint: duediligence_startupresponsetable_pkey, update_columns: answer}) {
            affected_rows
          }
        }`,
        variables: {
          answers: this.answers,
        },
        update: (store, { data: { insert_duediligence_startupresponsetable } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_duediligence_startupresponsetable.affected_rows ? 'Answers submitted successfully' : 'Failed to submit answers',
              icon: insert_duediligence_startupresponsetable.affected_rows ? 'CheckIcon' : 'XIcon',
              variant: insert_duediligence_startupresponsetable.affected_rows ? 'success' : 'warning',
            },
          })
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    applicationData: {
      query() {
        return gql`
        {
          duediligence_stagetable_by_pk(id: ${this.$route.params.id}) {
            duediligence_startupassessmenttables {
              question
              section
              id
              data_type
              duediligence_startupresponsetables(where: {startup_id: {_eq: ${this.$route.params.sid || 0}}}) {
                answer
                id
                startup_id
              }
            }
          }
        }`
      },
      update: data => data.duediligence_stagetable_by_pk?.duediligence_startupassessmenttables,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
